.k-listview.documents-list {
  margin-bottom: map-get($spacers, 3);
  border-width: 1px;
  @if $enable-rounded {
    border-radius: $border-radius;
  }
}

.bulk-download-btn-plus {
  text-align: right;
  z-index: 1;
  position: fixed;
  bottom: 15px;
  right: 15px;

  > div {
    @include font-size(12px);
    background-color: #7c858d;
    border-radius: 1px;
    color: #fefefe;
    line-height: 1;
    margin: 0 0 10px;
    padding: 3px 8px;
  }

  .k-button-solid-primary {
    @include font-size(25px);
    border-radius: 50%;
    box-sizing: content-box;
    line-height: 1;
    padding: 20px;
    height: 25px;
    width: 25px;
  }
}

.load-more {
  text-align: center;
  margin-bottom: map-get($spacers, 3);
}
