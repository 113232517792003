.portfolios-header {
  position: relative;
  background-image: $ccp-portfolios-section-image;
  background-size: cover;
  background-position: center center;
  height: 200px;

  @include media-breakpoint-up(md) {
    height: 300px;
  }

  .total-market-value {
    background: rgba($color: #fff, $alpha: 0.75);
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 0;
    padding: map-get($spacers, 1) map-get($spacers, 3);

    .total-market-value-label {
      @include font-size(18px);
      display: block;

      @include media-breakpoint-up(md) {
        display: inline;
        margin: 0 map-get($spacers, 2) 0 0;
      }
    }

    .total-market-value-number {
      @include font-size(32px);
      font-weight: $headings-font-weight;
      display: block;

      @include media-breakpoint-up(md) {
        display: inline;
      }
    }
  }
}
