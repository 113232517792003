.account-button {
  &:disabled,
  .k-button.k-disabled {
    opacity: 1;
  }

  .k-button-text {
    display: flex;
    align-items: center;
  }

  .account-button-text {
    margin-right: map-get($spacers, 2);
    text-align: right;
    max-width: 200px;

    .tagline {
      font-weight: normal;
      display: block;
      @include font-size(14px);
    }

    .name {
      font-weight: bold;
      display: block;
    }

    @include media-breakpoint-up(md) {
      max-width: 300px;
    }
  }

  .account-button-avatar {
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}
