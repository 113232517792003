.global-menu {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    border-top: $gray-400 solid 1px;

    li {
      margin: 0;
      border-bottom: $gray-400 solid 1px;

      a {
        padding: map-get($spacers, 2) 0;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $body-color;

        &:hover > *,
        &.active > * {
          text-decoration: underline;

          &.k-icon {
            text-decoration: none;
          }
        }

        .k-badge {
          left: 100%;
          transform: translate(0%, -50%);
          margin-left: map-get($spacers, 2);
        }
      }
    }
  }

  .k-icon {
    margin-right: map-get($spacers, 2);
  }
}

.global-navigation {
  margin-bottom: map-get($spacers, 5);
}

.secondary-navigation {
  margin-bottom: map-get($spacers, 5);

  ul {
    border: 0;

    li {
      border: 0;

      a:not(.switch-account) {
        padding: map-get($spacers, 1) 0;
      }
    }
  }
}
