.choose-language {
  margin-bottom: map-get($spacers, 3);

  .k-label {
    @include font-size(14px);
  }

  .k-dropdownlist {
    @include font-size(14px);
    i {
      @include font-size(16px);
      margin-right: map-get($spacers, 2);
    }
  }

  .k-popup .k-list-md {
    @include font-size(14px);
  }
}
