.conversation {
  &.disabled {
    .k-message-box {
      display: none;
    }
  }
}
.closed-conversation {
  @include font-size(14px);
  margin-top: 1rem;
  padding: 1rem;

  span {
    display: inline-block;
  }
}
