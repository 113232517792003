/** Google Fonts */
// @import url('//fonts.googleapis.com/css2?family=Open+Sans:wght@300;500;700&display=swap');
// @import url('https://fonts.cdnfonts.com/css/fsalbert');

/* Customization variables */
@import 'variables';

/* Product styles */
@import './src/assets/scss/main';

/* Custom local font */
@font-face {
  font-family: 'FS Albert';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/FSAlbert/OKQ8Web-Light.woff2) format('woff2'),
    url(../fonts/FSAlbert/OKQ8Web-Light.woff) format('woff');
}
@font-face {
  font-family: 'FS Albert';
  font-style: italic;
  font-weight: 300;
  src: url(../fonts/FSAlbert/OKQ8Web-LightItalic.woff2) format('woff2'),
    url(../fonts/FSAlbert/OKQ8Web-LightItalic.woff) format('woff');
}
@font-face {
  font-family: 'FS Albert';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/FSAlbert/OKQ8Web-Regular.woff2) format('woff2'),
    url(../fonts/FSAlbert/OKQ8Web-Regular.woff) format('woff');
}
@font-face {
  font-family: 'FS Albert';
  font-style: italic;
  font-weight: 400;
  src: url(../fonts/FSAlbert/OKQ8Web-Italic.woff2) format('woff2'),
    url(../fonts/FSAlbert/OKQ8Web-Italic.woff) format('woff');
}
@font-face {
  font-family: 'FS Albert';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/FSAlbert/OKQ8Web-Bold.woff2) format('woff2'),
    url(../fonts/FSAlbert/OKQ8Web-Bold.woff) format('woff');
}

/* Custom styles */
html {
  body {
    background-color: $html-background-color;
    color: $html-text-color;
    font-family: $base-font;
    font-weight: $font-weight-regular;
  }
}

a {
  color: $customer-color-link;
  text-decoration: none;

  &:hover {
    color: $customer-color-link-hover;
    text-decoration: underline;
    transition: all 0.3s ease-in-out;
  }
}

.badge {
  &.bg-primary {
    background: $customer-color-white !important;
    color: $customer-color-red !important;
    border: 1px solid $customer-color-red;
  }
}

.global-header {
  .logo {
    img {
      width: 132px;
      @media (min-width: 991px) {
        width: 165px;
      }
    }
  }
}

.global-footer {
  background-color: $footer-background-color;
  color: $footer-text-color;
  padding: 2rem 0;

  a {
    text-decoration: none;

    &:hover {
      color: $customer-color-white;
      text-decoration: underline;
    }
  }
}

.layout main .main-section {
  padding: map-get($spacers, 3) 0 0;

  @include media-breakpoint-up(md) {
    padding: map-get($spacers, 4) 0 0;
  }
  &:last-child {
    padding: map-get($spacers, 3) 0;

    @include media-breakpoint-up(md) {
      padding: map-get($spacers, 4) 0;
    }

    &.public {
      padding-top: 105px;
    }
  }
  &.documents header {
    margin-bottom: 1rem;
  }

  h1 {
    color: $customer-color-darkblue;
    font-weight: 700;
  }
}

main {
  .layout & {
    background: $customer-color-shadedwhite;
  }
  .layout.splash-layout & {
    background: transparent;
  }
}

.header.main-section {
  .container {
    h1 {
      margin: 9vh 0 0;
    }
  }
}

.global-menu {
  background-color: $customer-color-darkblue;
  color: $customer-color-white;
  .btn-close {
    color: $customer-color-white;
    top: 15px;
    right: 15px;
    background: transparent
      url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e')
      center/1em auto no-repeat;
    &:hover {
      color: $customer-color-lightgray;
    }
  }
  .user-info {
    .user-name {
      color: $customer-color-lightgray;
    }
    .switch-account {
      color: $customer-color-white;
    }
    h5 {
      font-weight: 700;
    }
  }

  .global-navigation {
    margin-bottom: 1.5rem;
  }

  ul {
    border-top: none;
    li {
      border-bottom: none;
      a {
        color: $customer-color-white;
        &.active > * {
          text-decoration: none;
          font-weight: 700;
        }
      }
    }
  }

  .secondary-navigation ul li a:not(.switch-account) {
    padding: 0.5rem 0;
  }

  .choose-language {
    border: 1px solid $customer-color-white;
    border-radius: 6px;
    .k-floating-label-container {
      display: block;
      margin-top: -12px;
      padding: 20px 5px 5px;
      > .k-label {
        margin-left: 10px;
        padding: 0px 7px;
        background-color: $customer-color-darkblue;
      }
      .k-picker-solid {
        background-color: $customer-color-darkblue;
        border-color: $customer-color-darkblue;
        color: $customer-color-white;
      }
    }
  }
}

.action-required.main-section {
  .container {
    h2 {
      @include font-size(15px);
      color: #888;
      margin: 0;
      padding: 20px 0 15px;
    }
  }
}

.latest-documents.main-section {
  .container {
    h2 {
      @include font-size(15px);

      color: #888;
      margin: 0;
      padding: 20px 0 15px;
    }

    .goto-documents {
      text-align: center;
    }
  }
}

.k-listview.documents-list {
  border: none;
  background: $customer-color-shadedwhite;

  .list-item {
    background: $customer-color-white;
    color: $customer-color-darkpurple;
    border: 1px solid $customer-color-lightgray;
    border-radius: 10px;
    margin-bottom: 13px;

    .list-item-checkbox {
      padding: 0.75rem 0 0.75rem 1.25rem;
    }

    .list-item-document {
      padding: 0.75rem 2rem 0.75rem 1.25rem;
    }

    &:last-child {
      border-bottom: 1px solid $customer-color-lightgray;
    }
  }
}

.open-action-list {
  border: none;
  background: $customer-color-shadedwhite;
  .list-group-item {
    color: $customer-color-darkpurple;
    border: 1px solid $customer-color-lightgray;
    border-radius: 10px;
    margin-bottom: 10px;

    .list-item-text .action-name {
      font-weight: 700;
    }

    .title.text-truncate {
      color: $customer-color-solid-neutral;
    }

    &:hover {
      background: lighten($customer-color-poloblue, 28%);
    }
  }
}

.conversations-list {
  border: none;
  background: $customer-color-shadedwhite;
  border-radius: 10px;

  .list-group-item {
    color: $customer-color-darkpurple;
    border-bottom: 1px solid $customer-color-lightgray;

    .list-item-text .action-name {
      font-weight: 700;
    }

    &:hover {
      background: lighten($customer-color-poloblue, 28%);
    }
  }
}

.k-button.k-primary,
.btn-primary {
  background-color: $button-primary-background-color;
  border: $button-primary-border;
  border-radius: $button-primary-border-radius;
  color: $button-primary-text-color;
  padding: $button-primary-padding;
  text-transform: $button-primary-text-transform;

  &:hover {
    background-color: $button-primary-background-color-hover;
    border: $button-primary-border-hover;
    color: $button-primary-text-color-hover;
  }

  &:focus {
    background-color: $button-primary-background-color-focus;
    border: $button-primary-border-focus;
    color: $button-primary-text-color-focus;
  }

  &.btn-sm {
    padding: 4px 10px;
    text-transform: none;
  }
}

.goto-documents > a,
a.btn-primary[href='/authentication/login'] {
  color: $customer-color-darkpurple;
  border: 2px solid $customer-color-darkpurple;
  border-radius: 30px;
  background-color: $customer-color-white;
  padding: 0.5rem 4rem 0.5rem 1.25rem;
  position: relative;
  font-size: 18px;
  display: inline-block;
  height: 42px;
  line-height: 24px;
  transition: 0.3s ease;
  &:after {
    content: ' ';
    display: block;
    width: 32px;
    height: 32px;
    position: absolute;
    right: 4px;
    top: 3px;
    border-radius: 50px;
    transition: 0.3s ease;
    background: $customer-color-red
      url('data:image/svg+xml,%3csvg width=%2722%27 height=%2718%27 viewBox=%270 0 22 18%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M1 9H20.5M20.5 9L12.5 17M20.5 9L12.5 1%27 stroke=%27white%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27/%3e%3c/svg%3e')
      center/16px auto no-repeat;
  }
  &:hover {
    text-decoration: none;
    &:after {
      background: $customer-color-lightblue
        url('data:image/svg+xml,%3csvg width=%2722%27 height=%2718%27 viewBox=%270 0 22 18%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M1 9H20.5M20.5 9L12.5 17M20.5 9L12.5 1%27 stroke=%27white%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27/%3e%3c/svg%3e')
        center/16px auto no-repeat;
    }
  }
}

.inbox header .k-button,
.documents header .filters-btn {
  color: $customer-color-darkpurple;
  border: 1px solid $customer-color-darkpurple;
  border-radius: 30px;
  background-color: $customer-color-white;
  padding: 0.35rem 1.25rem;
  &:hover {
    background-color: lighten($customer-color-poloblue, 28%);
  }
}

.k-badge-solid-primary {
  border-color: $customer-color-red;
  background-color: $customer-color-red;
}

.k-button-solid-primary,
.k-button-outline-secondary {
  border-radius: 30px;
}

.open-action-list.list-group .list-group-item .list-item-badge {
  border-color: $customer-color-red;
  color: $customer-color-red;
}

.goto-documents {
  margin: 1.5rem 0px;
}

.filters .close-btn {
  padding: 5px 8px;
}

.k-listview-content {
  overflow: visible;
}

.k-card {
  font-family: $base-font;
  border-width: 0;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  transition: $transition-base;
  color: $customer-color-darkpurple;
  border-radius: 0px;

  @at-root a & {
    .k-card-body:last-of-type {
      padding-right: 4rem;
    }
  }

  @at-root a &::after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    top: calc(50% - 12px);
    right: 2rem;
    border-radius: 30px;
    background: $customer-color-lightblue
      url('data:image/svg+xml,%3csvg width=%278%27 height=%2712%27 viewBox=%270 0 8 12%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M1 1L6 6L1 11%27 stroke=%27white%27 stroke-width=%272%27/%3e%3c/svg%3e')
      center/8px auto no-repeat;
    transition: 0.5s ease;
  }

  @at-root a:hover & {
    text-decoration: none;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    &::after {
      transform: translate(14px);
    }
  }

  .tagline {
    font-weight: normal;
    margin-bottom: 3px;
    color: $customer-color-poloblue;
  }

  .k-card-subtitle {
    @include font-size(14px);
    font-weight: normal;
    color: inherit;
  }
}

/* New requests */
.action-request {
  .k-card {
    border-radius: 10px;
  }

  figure {
    @include transition($btn-transition);
    background-color: $customer-color-lightblue;
    padding-left: map-get($spacers, 4);
    padding-right: map-get($spacers, 4);
    fill: $customer-color-white;
    border-radius: 10px;
    margin: 10px;
    min-height: 88px;

    .action-request-image {
      max-height: 40px;
      max-width: 40px;
    }
  }

  .k-card-title {
    font-weight: 700;
  }

  &:hover {
    text-decoration: none;
    figure {
      background-color: $customer-color-poloblue;
    }
  }
}

.workflow-task {
  .k-card {
    border-radius: 10px;
    &:after {
      display: none;
    }
    .k-card-title {
      font-weight: 700;
    }
    &:hover {
      transform: translate(14px);
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.select-account {
  .k-card:not(.k-card .k-card) {
    padding: map-get($spacers, 4);
  }

  .k-card.k-card-vertical {
    background-color: $customer-color-darkblue;
    border-radius: 10px;
  }

  .account-list-item .k-card-horizontal {
    border-radius: 10px;
  }

  .k-card-title {
    text-align: center;
    color: $customer-color-white;
    position: relative;
    margin-bottom: 1.5rem;

    &::after {
      content: '';
      border-bottom: 1px solid $customer-color-white;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: -0.75rem;
    }
  }

  .k-card-subtitle {
    text-align: center;
    color: $customer-color-white;
  }

  .k-listview {
    background-color: $customer-color-darkblue;
  }
}

.k-avatar-solid-light {
  border-color: #f3f1f0;
  color: $customer-color-darkblue;
  background-color: #f3f1f0;
}

.btn-secondary {
  background-color: $button-secondary-background-color;
  border: $button-secondary-border;
  border-radius: $button-secondary-border-radius;
  color: $button-secondary-text-color;
  padding: $button-secondary-padding;
  text-transform: $button-secondary-text-transform;

  &:hover {
    background-color: $button-secondary-background-color-hover;
    border: $button-secondary-border-hover;
    color: $button-secondary-text-color-hover;
  }

  &:focus {
    background-color: $button-secondary-background-color-focus;
    border: $button-secondary-border-focus;
    color: $button-secondary-text-color-focus;
  }
}

.k-window {
  font-family: $base-font;
  border-radius: 10px;

  &-title {
    color: $customer-color-darkblue;
  }
}

.k-content,
.k-button,
.k-listview,
.k-upload {
  font-family: $base-font;
}

.load-more .k-button {
  border-radius: 30px;
  min-width: 150px;
}

.k-list {
  .k-item {
    &.k-state-selected {
      background-color: $button-primary-background-color;
    }

    &:hover {
      &.k-state-selected {
        background-color: $button-primary-background-color-hover;
      }
    }
  }
}

.k-loader-primary {
  color: $button-primary-background-color;

  .k-loader-segment {
    color: $button-primary-background-color;
  }
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  background-color: $button-primary-background-color;
  border-color: $button-primary-background-color;
}

.list-item .list-item-document .list-item-document-name {
  a {
    color: $button-primary-background-color;

    &:hover {
      color: $button-primary-background-color-hover;
    }
  }
}

.document-modal-dialog {
  .k-button {
    background-color: $button-primary-background-color;
    color: $customer-color-white;

    &:hover {
      background-color: $button-primary-background-color-hover;
    }
  }

  .k-dialog-titlebar-actions .k-button {
    background-color: $customer-color-shadedwhite !important;
    color: $customer-color-darkpurple;
  }
}

.support-btns.sticky {
  background-color: $customer-color-lightblue;
  margin: -1rem 0 1rem 0;

  @include media-breakpoint-up(md) {
    margin: -1.5rem 0 1rem 0;
  }
  .note {
    color: $customer-color-white;
  }
  .btn-secondary {
    border: 1px solid #fff;
    &:hover {
      border: 1px solid #fff;
    }
  }
}

.bulk-download-btn-plus {
  bottom: 95px;
  z-index: 99;
}

// Forms adjustments

.formio-form-wrapper {
  margin-top: 1.5rem;
  a {
    color: $customer-color-darkblue;
  }

  .formio-form {
    /* Pagination / Tabs */
    nav ul.pagination .page-item {
      .page-link {
        color: $customer-color-darkblue;
        padding: 8px 20px;
      }

      &.active {
        .page-link {
          background-color: $button-primary-background-color;
          border: $button-primary-border;
        }
      }
    }
  }

  .formio-component-panel {
    margin-bottom: 1.5rem;
  }

  .card {
    .card-header {
      padding-top: 1rem;
      padding-bottom: 0.5rem;
      .card-title {
        font-weight: 700;
      }
    }
  }

  .fa-question-circle::before {
    color: $customer-color-darkblue;
    font-weight: 300;
  }
}

// Bootstrap fixes
.form-check-input:checked {
  background-color: $button-primary-background-color;
  border: $button-primary-border;
}

.k-chat .k-alt .k-bubble {
  background-color: $customer-color-lightblue;
  border-color: $customer-color-lightblue;
}

.k-chat .k-message-box .k-button:hover {
  color: $customer-color-darkblue;
}

// Conversations styling
.global-navigation .badge,
.global-header .btn-menu i.notification-badge::after,
.conversations-list.k-listview
  .list-item
  .list-item-conversation
  a.has-new-message::before {
  background-color: $customer-color-red;
}
