.alerts {
  // margin-bottom: 1rem;

  .alert {
    border-radius: 0;
    margin-bottom: 0;

    .container {
      position: relative;
      padding-right: 45px;
    }

    .close {
      cursor: pointer;
      padding: 1px 3px;
      position: absolute;
      top: 0;
      right: 0;
    }

    i {
      font-size: 20px;
      margin-right: 10px;
    }
  }
}
