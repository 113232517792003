.inbox {
  header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    h1 {
      margin: 0;
    }

    .k-button {
      color: inherit;
      margin-left: auto;
    }
  }
}
