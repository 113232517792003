.conversations-list {
  border-width: 1px;
  @if $enable-rounded {
    border-radius: $border-radius;
  }

  &.list-group {
    .list-group-item {
      padding: map-get($spacers, 3) calc($grid-gutter-width / 2);
      border-bottom: $border-color solid $border-width;
      display: flex;
      align-items: center;

      &.new-message {
        font-weight: 500;
      }

      .list-item-text {
        margin-left: map-get($spacers, 2);
      }

      .list-item-date {
        @include font-size(14px);
        color: $gray-600;
        font-weight: 400;
        margin-left: auto;
      }
    }
  }
}
