.custom-loader {
  background-color: rgba($color: #fff, $alpha: 1);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 800;
  position: fixed;
  top: 0;
  left: 0;

  &.transparent {
    background-color: rgba($color: #fff, $alpha: 0.85);
  }

  .center {
    background-color: #fefefe;
    padding: 10px;
    text-align: center;
  }

  .text {
    display: block;
    max-width: 360px;
  }
}
