.filters {
  background-color: $body-bg;
  display: none;
  height: 100vh;
  overflow-y: auto;
  padding: calc($grid-gutter-width * 2) calc($grid-gutter-width / 2) 0;
  width: 100%;
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;

  @include media-breakpoint-up(md) {
    background-color: transparent;
    margin-top: 20px;
    overflow: visible;
    padding: 0;
    height: auto;
    width: auto;
    z-index: 1;
    position: relative;
    top: auto;
    left: auto;
  }

  &.on {
    display: block;
  }

  .close-btn {
    position: absolute;
    top: calc($grid-gutter-width / 2);
    right: calc($grid-gutter-width / 2);

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .row {
    .col {
      margin-bottom: map-get($spacers, 3);
    }
  }
}

.filter-buttons {
  margin: 30px 0;

  @include media-breakpoint-up(md) {
    margin: 25px 0 10px;
  }

  button {
    width: 100%;
  }
}
