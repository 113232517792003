.formio-form-wrapper {
  .form-group {
    margin-bottom: map-get($spacers, 3);
  }

  .input-group {
    .input-group-prepend {
      .input-group-text {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .input-group-append {
      .input-group-text {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .input-group-text .fa {
    line-height: inherit;
  }

  label:not(.col-form-label):not(.form-check-label) {
    @include font-size($form-label-font-size);
    margin-bottom: $form-label-margin-bottom;
    font-style: $form-label-font-style;
    font-weight: $form-label-font-weight;
    color: $form-label-color;
  }

  .fa-times-circle-o::before {
    content: '\f00d';
  }

  .fa-plus-square-o::before {
    content: '\f0fe';
  }

  .fa-minus-square-o::before {
    content: '\f146';
  }

  .editgrid-listgroup.list-group {
    margin-bottom: map-get($spacers, 2);
  }

  .choices__input {
    background: $input-bg;
  }

  .formio-component {
    &.tooltip-only {
      > div {
        display: none;
      }
    }
  }

  .formio-custom-hidden {
    display: none !important;
  }

  // Error message anchor links in case of failed validation
  [ref='errorRef'] span {
    text-decoration: $link-decoration;

    &:hover {
      text-decoration: $link-hover-decoration;
    }
  }

  // Link to top in case of failed validation of form
  [ref='buttonMessageContainer'].has-error .help-block {
    display: inline-block;
    color: $form-feedback-invalid-color;
    text-decoration: $link-decoration;
  }

  // Fix for Formio tooltips bug where tooltips are multiplied
  [ref='tooltip'] {
    // Hide all tooltips
    ~ [data-tippy-root] {
      display: none;
    }

    // Show only last one, once tooltips should be visible
    &[aria-expanded='true'] ~ [data-tippy-root]:last-of-type {
      display: block;
    }
  }
}
