.portfolios {
  h1 {
    margin-bottom: map-get($spacers, 3);
  }

  .portfolio-drop-down {
    width: auto;
    border: none;

    .k-input-inner {
      padding: 0;
      line-height: 1.5;
    }
  }

  h3.portfolio-value {
    margin-bottom: map-get($spacers, 5);
  }

  .k-tabstrip {
    margin-bottom: map-get($spacers, 2);
  }

  .portfolios-last-updated-text,
  .disclaimer-text {
    @include font-size(14px);
    color: var(--bs-secondary);
  }
}
