.global-header {
  background: $ccp-global-header-bkg-color;
  color: $ccp-global-header-text-color;
  padding: map-get($spacers, 2) 0;

  @if $ccp-splash-layout-transparent-header {
    @at-root .layout.splash-layout & {
      background: transparent;
    }
  }

  .container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .logo,
  .btn-menu {
    color: $ccp-global-header-text-color;
    display: flex;
    align-items: center;
  }

  .logo {
    min-height: 60px;

    img {
      margin-top: -2px;
      width: 150px;
    }

    &:hover {
      img {
        opacity: 0.75;
      }
    }
  }

  .header-buttons {
    display: flex;
    flex-wrap: nowrap;
    margin-left: auto;

    .k-avatar {
      color: inherit;
    }
  }

  .btn-menu {
    margin-left: map-get($spacers, 2);
    padding: 0;
    text-decoration: none;
    box-shadow: none;

    &:hover {
      color: shift-color($ccp-global-header-text-color, $link-shade-percentage);
    }

    &:active:focus,
    &:focus {
      outline: none;
      box-shadow: none;
    }

    span {
      display: none;
      @include media-breakpoint-up(sm) {
        display: inline;
      }
    }

    i {
      @include font-size(22px);
      margin-left: map-get($spacers, 2);
      position: relative;

      &.notification-badge {
        &::after {
          background-color: $ccp-global-header-notification-color;
          border: 2px solid $ccp-global-header-bkg-color;
          border-radius: 50%;
          content: '';
          display: inline-block;
          height: 11px;
          width: 11px;
          position: absolute;
          left: -1px;
          top: 2px;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
