.portfolios-accordion {
  .portfolio-details {
    margin-bottom: map-get($spacers, 4);

    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  .market-value {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: map-get($spacers, 3);

    @include media-breakpoint-up(lg) {
      width: 33.33333333%;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(xxl) {
      width: 40%;
    }

    dl {
      margin-bottom: 0;
    }

    dt {
      @include font-size(18px);
      font-weight: normal;
    }

    dd {
      @include font-size(32px);
      font-weight: $headings-font-weight;
    }
  }

  .total-gain {
    @include media-breakpoint-up(lg) {
      width: 66.66666667%;
      border-left: $list-group-border-width solid $list-group-border-color;
      padding-left: map-get($spacers, 5);
    }

    @include media-breakpoint-up(xxl) {
      width: 60%;
    }
  }

  .list-group-item {
    padding-right: 0;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
  }

  .portfolio-link {
    text-align: center;
    margin-bottom: map-get($spacers, 4);
  }

  .portfolios-last-updated-text {
    margin-bottom: 0;
  }
}
