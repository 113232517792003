.portfolio-graph {
  margin-bottom: map-get($spacers, 4);

  .portfolio-graph-date-picker {
    margin-bottom: map-get($spacers, 4);
  }

  .k-datepicker {
    &.start-date {
      @include media-breakpoint-down(sm) {
        margin-bottom: map-get($spacers, 1);
      }
    }
  }

  .k-floating-label-container {
    display: flex;
  }
}

.k-calendar-td {
  &.k-disabled {
    opacity: 0.25;
  }
}
