.global-menu {
  position: relative;
  background-color: $white;
  padding: calc($grid-gutter-width * 2) calc($grid-gutter-width / 2)
    calc($grid-gutter-width / 2);

  @include media-breakpoint-up(sm) {
    padding: calc($grid-gutter-width * 2);
  }

  .btn-close {
    position: absolute;
    top: 17px;
    right: 9px;
  }
}
