.quick-search {
  position: relative;

  &.row {
    margin-bottom: 20px;
  }

  input {
    padding-right: 40px;
  }

  i {
    @include font-size(20px);
    color: rgba($color: #5a6268, $alpha: 0.8);
    position: absolute;
    top: calc(50% + (1.5 * 1rem) / 2);
    right: 15px;
    transform: translate(-50%, -50%);
  }
}
