//-------------------------------
// Global styles - product repo
//-------------------------------
html {
  // Viewport should be the same width no matter if there is enough content or not
  overflow-y: scroll;
  // Propagate container height so that we can use flex box column on layout
  height: 100%;
}

body {
  height: 100%;
}

#root,
#storybook-root {
  height: 100%;
}

.k-card {
  @include transition($btn-transition);
  @at-root a:hover & {
    border-color: $primary;
  }
}

// Making sure that extremely long word in these elements doesn't break layout
h1,
h2,
h3,
h4,
h5,
.h5,
p,
.k-card-title {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

// Modal window / Dialog component additional styles
.k-window {
  border-radius: 0;
  border-width: 0;
  height: 100%;
  width: 100%;

  @include media-breakpoint-up(sm) {
    border-radius: 0.25rem 0.25rem 0 0;
    border-width: 1px;
    height: auto;
    max-height: calc(100vh - #{$grid-gutter-width});
    width: calc(
      #{map-get($container-max-widths, 'sm')} - #{$grid-gutter-width}
    );
  }

  @include media-breakpoint-up(md) {
    border-radius: 0.25rem;
    max-width: calc(100% - #{$grid-gutter-width});

    width: calc(
      #{map-get($container-max-widths, 'md')} - #{$grid-gutter-width}
    );
  }

  @include media-breakpoint-up(lg) {
    width: calc(
      #{map-get($container-max-widths, 'lg')} - #{$grid-gutter-width}
    );
  }

  @include media-breakpoint-up(xl) {
    width: calc(
      #{map-get($container-max-widths, 'xl')} - #{$grid-gutter-width}
    );
  }

  @include media-breakpoint-up(xxl) {
    width: calc(
      #{map-get($container-max-widths, 'xxl')} - #{$grid-gutter-width}
    );
  }

  .k-upload {
    .k-progressbar {
      display: none;
    }
  }
}

.k-window-content {
  .note {
    @include font-size(12px);
    color: #6c757d;
    display: block;
    margin: map-get($spacers, 2) 0 map-get($spacers, 2);
  }
}

// Make sure that k-listview plays nice with bootstrap's list-group and global border radius
.k-listview {
  .k-listview-content {
    border-radius: inherit;
  }
}

// Make sure that tables in tabs have full width
.k-tabstrip {
  .k-animation-container {
    display: block;
  }
}

// Make sure title in expander is not touching subtitle
.k-expander {
  .k-expander-title {
    text-transform: none;
  }

  .k-spacer {
    width: map-get($spacers, 3);
  }
}

// IK-1286 - Change hand to arrow (hover)
.k-grid-md .k-table-th > .k-cell-inner > .k-link {
  cursor: default;
}
