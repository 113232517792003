.select-account {
  .k-card-subtitle {
    margin-bottom: map-get($spacers, 3);
  }

  a.account-list-item {
    display: block;
    text-decoration: none;

    &:not(:last-child) {
      margin-bottom: map-get($spacers, 3);
    }

    .account-list-icon {
      padding-right: 0;
      flex-grow: 0;
    }

    .account-list-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .k-avatar {
    margin-right: 0;
  }
}
