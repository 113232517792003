.portfolio-table {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  .k-treelist-scrollable {
    overflow: auto;
  }

  th.text-end .k-cell-inner {
    justify-content: end;
  }

  th.text-center .k-cell-inner {
    justify-content: center;
  }

  .portfolio-filters .col {
    margin-bottom: map-get($spacers, 3);
  }

  .highlighted-row {
    font-style: italic;
    background: $gray-100;
  }

  .portfolio-load-more {
    text-align: center;
    margin: map-get($spacers, 3) 0 map-get($spacers, 3);
  }
}
