.open-action-list {
  border-width: 1px;
  margin-bottom: 50px;

  @if $enable-rounded {
    border-radius: $border-radius;
  }

  &.list-group {
    .list-group-item {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: 1fr;
      align-items: flex-start;
      padding: map-get($spacers, 3) calc($grid-gutter-width / 2);

      &.with-badge {
        display: block;
      }

      @include media-breakpoint-up(sm) {
        &.with-badge {
          display: grid;
          grid-template-columns: 1fr auto;
          grid-template-rows: 1fr;
          align-items: flex-start;
        }
      }

      .icon-text {
        display: grid;
        grid-template-columns: auto 1fr;
      }

      .list-item-icon {
        margin: map-get($spacers, 1) map-get($spacers, 2) 0 0;
      }

      .list-item-text {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;

        > * {
          display: block;
        }

        .action-name {
          font-weight: 500;
        }
      }

      .list-item-badge {
        @include font-size(12px);
        border: 1px solid $primary;
        border-radius: 100px;
        color: $primary;
        margin: calc(var(--bs-list-group-item-padding-y) * 0.5) 0 0 auto;
        overflow: hidden;
        padding: 2px 6px;
        text-overflow: ellipsis;
        text-align: right;
        white-space: nowrap;
        z-index: 10;
      }
    }
  }
}
