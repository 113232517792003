/** Variables */
/** Fonts */
$base-font: 'FS Albert', arial, helvetica, sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

/** Colors */
/** Primary Colors */
$customer-color-black: #000;
$customer-color-white: #fff;
$customer-color-shadedwhite: #f3f3f3;
$customer-color-lightgray: #e5e5e5;
$customer-color-solid-neutral: #67686b;
$customer-color-darkpurple: #3d3853;
$customer-color-purple: #4d4859;
$customer-color-lightblue: #5871b4;
$customer-color-darkblue: #20419a;
/** Secondary Colors */
$customer-color-green: #00a99d;
$customer-color-yellow: #f5cb63;
$customer-color-lightgreen: #8adebe;
$customer-color-poloblue: #8fa0cc;
$customer-color-red: #d94940;
$customer-color-lightred: #e5857f;

/** HTML */
$html-background-color: $customer-color-shadedwhite;
$html-text-color: $customer-color-darkpurple;

/** Header */
$header-min-hight: 50px;
$header-border-bottom: 1px solid $customer-color-white;

/** Menu */
$menu-text-color: $customer-color-darkpurple;

/** Footer */
$footer-background-color: $customer-color-darkblue;
$footer-min-height: 20px;
$footer-text-color: $customer-color-white;
$footer-links-color: $customer-color-white;

/** Links */
$customer-color-link: $customer-color-darkblue;
$customer-color-link-hover: $customer-color-darkblue;

/** Buttons */
/** Primary (Action) Button */
$button-primary-background-color: $customer-color-darkblue;
$button-primary-background-color-hover: darken($customer-color-darkblue, 5%);
$button-primary-background-color-focus: $customer-color-darkblue;
$button-primary-text-color: $customer-color-white;
$button-primary-text-color-hover: $customer-color-white;
$button-primary-text-color-focus: $customer-color-white;
$button-primary-border: 1px solid $customer-color-darkblue;
$button-primary-border-hover: 1px solid $customer-color-darkblue;
$button-primary-border-focus: 1px solid $customer-color-darkblue;
$button-primary-text-transform: none;
$button-primary-padding: 0.5rem 1.25rem;
$button-primary-border-radius: 50px;
/** Secondary */
$button-secondary-background-color: $customer-color-white;
$button-secondary-background-color-hover: $customer-color-shadedwhite;
$button-secondary-background-color-focus: $customer-color-shadedwhite;
$button-secondary-text-color: $customer-color-darkpurple;
$button-secondary-text-color-hover: $customer-color-darkpurple;
$button-secondary-text-color-focus: $customer-color-darkpurple;
$button-secondary-border: 1px solid $customer-color-purple;
$button-secondary-border-hover: 1px solid $customer-color-darkpurple;
$button-secondary-border-focus: 1px solid $customer-color-darkpurple;
$button-secondary-text-transform: none;
$button-secondary-padding: 0.5rem 1.25rem;
$button-secondary-border-radius: 50px;

/* Product variables */

/* Header */
$ccp-global-header-bkg-color: $customer-color-white;
$ccp-global-header-text-color: $customer-color-darkpurple;
$ccp-splash-layout-bkg-image: linear-gradient(
    rgba(255, 255, 255, 0.05),
    rgba(0, 0, 0, 0.5)
  ),
  url('../media/road.jpg');
$ccp-splash-layout-transparent-header: true;

/* Bootstrap variables */
$primary: $customer-color-darkblue;
$list-group-color: $customer-color-darkblue;
$list-group-bg: $customer-color-white;
$list-group-hover-bg: rgba(
  $color: $customer-color-poloblue,
  $alpha: 0.4,
);
$list-group-action-active-bg: rgba(
  $color: $customer-color-lightblue,
  $alpha: 0.6,
);

$card-cap-bg: $customer-color-white;
$card-cap-color: $customer-color-darkblue;
$card-border-width: 0;
