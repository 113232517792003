//-----------------------------------------------------------------
// Functions and variables specific to Customer Portal
//-----------------------------------------------------------------
@import './product/functions';
@import './product/variables';

//-----------------------------------------------------------------
// Vendor imports
//-----------------------------------------------------------------
// Kendo Bootstrap theme
@import '~@progress/kendo-theme-bootstrap/dist/all';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Formio.js styling
@import '~formiojs/dist/formio.full.min.css';

// Temporary fixes for Formio since we use Bootstrap v5
// TODO: Once we switch to Formio version which supports Bootstrap v5, this file should be examined and probably removed
@import './product/formio-bs5-fixes';

// Fontawesome icons
@import '~@fortawesome/fontawesome-pro/css/all';

//-----------------------------------------------------------------
// Global styles
//-----------------------------------------------------------------
@import './product/global';

//-----------------------------------------------------------------
// Component styles
//-----------------------------------------------------------------
@import './product/components';
