.layout {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &.splash-layout {
    background-color: $ccp-splash-layout-bkg-color;
    @if $ccp-splash-layout-bkg-image {
      background-image: $ccp-splash-layout-bkg-image;
      background-size: cover;
    }

    &.home-public-layout {
      @if $ccp-splash-home-public-layout-layout-bkg-image {
        background-image: $ccp-splash-home-public-layout-layout-bkg-image;
      }
    }

    &.select-account-layout {
      @if $ccp-splash-select-account-layout-bkg-image {
        background-image: $ccp-splash-select-account-layout-bkg-image;
      }
    }

    &.logged-out-layout {
      @if $ccp-splash-logged-out-layout-bkg-image {
        background-image: $ccp-splash-logged-out-layout-bkg-image;
      }
    }

    &.resource-not-found {
      @if $ccp-splash-resource-not-found-layout-bkg-image {
        background-image: $ccp-splash-resource-not-found-layout-bkg-image;
      }
    }

    &.not-found {
      @if $ccp-splash-not-found-layout-bkg-image {
        background-image: $ccp-splash-not-found-layout-bkg-image;
      }
    }

    main {
      @include media-breakpoint-up(md) {
        width: #{map-get($container-max-widths, 'sm')};
        margin: 0 auto;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 10vmin;
      }
    }

    .choose-language {
      margin: 0;
      text-align: right;

      .k-label {
        display: none;
      }
    }
  }

  main {
    .main-section {
      padding: map-get($spacers, 3) 0;

      @include media-breakpoint-up(md) {
        padding: map-get($spacers, 5) 0;
      }
    }
  }

  .global-menu {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    transform: translateX(100%);
    transition: transform 0.2s ease-out;
    will-change: transform;
    z-index: 600;
    @include media-breakpoint-up(md) {
      width: 50%;
    }

    @include media-breakpoint-up(xl) {
      width: 35%;
    }
  }

  &.global-menu-visible {
    .global-menu {
      transform: translateX(0);
    }

    .global-menu-overlay {
      @include media-breakpoint-up(md) {
        opacity: 1;
        visibility: visible;
        backdrop-filter: blur(5px);
      }
    }
  }

  .global-menu-overlay {
    background-color: transparentize($black, 0.25);
    position: fixed;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(0);
    transition: opacity 0.2s ease-out;
    will-change: opacity, backdrop-filter;
    cursor: pointer;
    z-index: 500;
  }
}
