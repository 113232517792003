.desktop-navigation {
  background: $ccp-global-header-bkg-color;
  border-color: rgba($color: #fefefe, $alpha: 0.25);
  border-style: solid;
  border-width: 1px 0 0;
  color: $ccp-global-header-text-color;
  display: none;

  @if $ccp-splash-layout-transparent-header {
    @at-root .layout.splash-layout & {
      background: transparent;
    }
  }

  @include media-breakpoint-up(md) {
    display: block;
  }

  .container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 map-get($spacers, 2);
      padding: 0;

      @include media-breakpoint-up(md) {
        margin: 0 map-get($spacers, 5) 0 0;
      }

      a {
        margin-left: -(map-get($spacers, 2));
        color: $ccp-global-header-text-color;
        display: inline-block;
        padding: map-get($spacers, 2);
        text-decoration: none;
        white-space: nowrap;

        &:hover {
          color: shift-color(
            $ccp-global-header-text-color,
            $link-shade-percentage
          );
        }
      }
    }
  }
}
