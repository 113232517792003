.publications-section {
  ul {
    list-style: none;
    margin: map-get($spacers, 2) 0 map-get($spacers, 4);
    padding: 0;

    li {
      // border-bottom: 1px solid #ccc;
      padding: map-get($spacers, 2) 0;

      a {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        text-decoration: none;
        font-weight: $headings-font-weight;

        .item-text {
          span {
            display: block;

            &.item-link-label {
              // padding: 0 map-get($spacers, 2) 0 0;
              text-decoration: underline;
            }

            &.item-date {
              @include font-size(14px);
              color: var(--bs-secondary);
              font-weight: 400;
            }
          }
        }

        .icon {
          @include font-size(24px);
          display: inline-flex;
          margin-left: auto;
          padding: 0 map-get($spacers, 2);
        }
      }
    }
  }
  .publications-link {
    margin-bottom: map-get($spacers, 3);
    text-align: center;
  }
}
