.task-action {
  ol.attachments-list {
    margin: map-get($spacers, 4) 0 map-get($spacers, 3);
  }

  .system-text {
    font-style: italic;
  }

  .action-title {
    .title-link {
      margin-bottom: 1rem;
    }
    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      .title-link {
        margin-bottom: 0px;
        margin-left: auto;
      }
      span {
        line-height: 2.5;
        white-space: nowrap;
      }
    }
  }

  .text-link {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
}

.workflow-action-form {
  border: 1px solid #dee2e6;
  margin-bottom: 1rem;

  &.no-submit,
  .formio-read-only {
    .formio-component-submit {
      display: none;
    }
  }

  .support-btns {
    margin: 0;
  }

  .formio-form-wrapper {
    padding: 0.5rem 1.25rem;
  }
}
