.document-modal-dialog {
  .k-window-titlebar {
    .document-category {
      @include font-size(14px);
      font-style: italic;
    }

    h5 {
      margin-bottom: 0;
    }

    .document-contacts {
      @include font-size(14px);

      span {
        &::after {
          content: '\00B7';
          display: inline-block;
          padding: 0 5px;
        }

        &:last-child::after {
          display: none;
        }
      }
    }
  }

  .k-window-title {
    display: block;

    &::before {
      content: none;
    }
  }

  iframe {
    border: 0;
  }

  object {
    width: 100%;
    height: calc(100vh - 15rem);
  }
}
