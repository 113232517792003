.action-request {
  display: flex;
  padding: map-get($spacers, 3) calc($grid-gutter-width / 2);

  .k-icon {
    margin: map-get($spacers, 1) map-get($spacers, 2) 0 0;
  }

  .request-info {
    flex-grow: 1;

    @include media-breakpoint-up(md) {
      display: flex;
    }
  }

  .request-info-text {
    @include media-breakpoint-up(md) {
      flex-grow: 1;
    }
  }

  .action-name {
    font-weight: $headings-font-weight;
    margin-bottom: 0;
  }

  .action-heading {
    margin-bottom: 0;
  }

  .badge {
    color: $primary;
    border: 1px solid $primary;
    border-radius: 100px;
    font-weight: normal;

    @include media-breakpoint-up(md) {
      margin-left: auto;
      align-self: start;
    }
  }
}
