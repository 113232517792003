.support-btns {
  background-color: #fbfbfb;

  margin: -1rem 0 1rem 0;
  padding: 0.75rem 0;
  width: 100%;
  z-index: 500;

  @include media-breakpoint-up(md) {
    margin: -3rem 0 1rem 0;
  }

  .container {
    display: flex;
    align-items: center;
  }

  &.sticky {
    position: sticky;
    top: 0px;
    justify-content: center;
  }

  .note {
    color: #888;
    @include font-size(12px);
    margin-left: auto;
    margin-right: 10px;
  }
}

.submission-saved {
  max-width: 800px;
}
