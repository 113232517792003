.upload-file {
  margin-top: map-get($spacers, 4);

  .k-upload {
    .k-progressbar {
      display: none;
    }
  }

  .note {
    @include font-size(12px);
    color: #6c757d;
    display: block;
    margin: map-get($spacers, 2) 0 map-get($spacers, 2);
  }

  > .k-button {
    margin-top: map-get($spacers, 3);
  }
}
