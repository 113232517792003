.user-info {
  margin-bottom: map-get($spacers, 5);

  .user-name {
    color: #6c757d;
    font-size: 0.8rem;
  }

  .switch-account {
    display: inline-block;
    font-size: 0.8rem;
    font-weight: 500;
  }
}
