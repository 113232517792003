.temp-response {
  max-width: 800px;

  h1 {
    margin-bottom: 25px;
  }

  .action-btns {
    button {
      margin: 20px 15px 0 0;
    }
  }
}
